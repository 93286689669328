import React, { useState, useEffect } from "react";
import { Card, Button, Tabs, Select, Typography, Checkbox, Row, Col, Form, Spin, message, Modal, Input } from "antd";
import { getTabData } from "../../../services/generic";
import { getOAuthHeaders } from "../../../constants/oAuthValidation";
import Axios from "axios";
import { LoadingOutlined, SearchOutlined, CloseCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import Scrollbars from "react-custom-scrollbars";

const { TabPane } = Tabs;
const { Search } = Input;
const { Text } = Typography;

const WindowComponent = () => {
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [applications, setApplications] = useState([]);
  const [selectedAppIndex, setSelectedAppIndex] = useState(0);
  const [menuStructure, setMenuStructure] = useState([]);
  const [rolePermissions, setRolePermissions] = useState([]);
  const [modifiedPermissions, setModifiedPermissions] = useState(new Set());
  const [loading, setLoading] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState("0");
  const [showData, setShowData] = useState(false);
  const [menuSearch, setMenuSearch] = useState("");
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [changedPermissions, setChangedPermissions] = useState([]);
  const [tabWiseChanges, setTabWiseChanges] = useState({});
  const genericUrl = process.env.REACT_APP_genericUrl;

  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      backgroundColor: "#c1c1c1",
      borderRadius: "5px",
      width: "8px",
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  const renderView = ({ style, ...props }) => {
    const viewStyle = {
      color: "#00000",
    };
    return <div className="box" style={{ ...style, ...viewStyle }} {...props} />;
  };

  // Keep the roles fetch in useEffect
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await getTabData({
          windowId: "7349",
          ad_tab_id: "F189888FEEB6406AA22E0752CD7B7656",
          startRow: "0",
          endRow: "50",
        });
        setRoles(response || []);
      } catch (error) {
        console.error("Failed to fetch roles:", error);
      }
    };
    fetchRoles();
  }, []);

  // Keep applications fetch on role selection
  useEffect(() => {
    const fetchApplications = async () => {
      if (!selectedRoleId) return;

      try {
        setLoading(true);
        const { access_token } = getOAuthHeaders();
        const appId = localStorage.getItem("appId");

        const response = await Axios.post(
          genericUrl,
          {
            query: `
              query {
                getTableData(
                  tableName: "cs_menu_schema",
                  columns: "app_id,app_name",
                  whereClause: "cs_menu_schema_id in (select cs_menu_schema_id from cs_role_application where cs_role_id = '${selectedRoleId}')",
                  orderByClause: null,
                  limit: 10000
                ) {
                  data
                }
              }
            `,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
              appId,
            },
          }
        );

        const apps = JSON.parse(response?.data?.data?.getTableData?.data || "[]");
        setApplications(apps);
        setSelectedAppIndex(0); // Reset the selected application index
      } catch (error) {
        console.error("Error fetching applications:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchApplications();
  }, [selectedRoleId]);

  // Modified role selection handler
  const handleRoleChange = (value) => {
    const role = roles.find((r) => r["3AAD9D715B3441F0B69205F1300FDE7B"] === value);
    setSelectedRole(value);
    setSelectedRoleId(role["BF7571952CB244008C3519C4B9F96CD2"]);
    setShowData(false); // Reset the display when role changes
    setModifiedPermissions(new Set()); // Reset modifications
    setMenuStructure([]); // Reset menu structure
    setRolePermissions([]); // Reset permissions
  };

  // The Go button handler remains separate
  const handleGoClick = async () => {
    if (!selectedRoleId || !applications[selectedAppIndex]?.app_id) return;

    try {
      setLoading(true);
      const { access_token } = getOAuthHeaders();
      const appId = localStorage.getItem("appId");

      const [menuResponse, permissionsResponse] = await Promise.all([
        Axios.post(
          genericUrl,
          {
            query: `query { getRoleAccessMenu(applicationId: "${applications[selectedAppIndex].app_id}") }`,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
              appId,
            },
          }
        ),
        Axios.post(
          genericUrl,
          {
            query: `query { getRolePermissions(roleId:"${selectedRoleId}", applicationId: "${applications[selectedAppIndex].app_id}") }`,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
              appId,
            },
          }
        ),
      ]);

      const parsedMenu = JSON.parse(menuResponse?.data?.data?.getRoleAccessMenu || "[]");
      const parsedPermissions = JSON.parse(permissionsResponse?.data?.data?.getRolePermissions || "[]");

      setMenuStructure(parsedMenu[0]?.children || []);
      setRolePermissions(parsedPermissions);
      setShowData(true);
    } catch (error) {
      console.error("Error fetching menu and permissions:", error);
    } finally {
      setLoading(false);
    }
  };
  const handlePermissionChange = (menuId, permission, checked, tabTitle) => {
    const updatedPermissions = [...rolePermissions];
    const menuIndex = updatedPermissions.findIndex((p) => p.recordId === menuId);

    if (menuIndex !== -1) {
      const oldValue = updatedPermissions[menuIndex][permission.toLowerCase()];
      const newValue = checked ? "Y" : "N";
      
      if (oldValue !== newValue) {
        setTabWiseChanges(prev => {
          const menuName = updatedPermissions[menuIndex].name;
          
          if (!prev[tabTitle]) {
            prev[tabTitle] = [];
          }
          
          const changeDescription = {
            menuName,
            permission: permission.toLowerCase(),
            type: checked ? "added" : "removed",
            description: `${checked ? "Added" : "Removed"} ${permission.toLowerCase()} permission for ${menuName}`
          };
          
          // Remove any existing changes for the same menu and permission
          const filteredChanges = prev[tabTitle].filter(
            change => !(change.menuName === menuName && change.permission === permission.toLowerCase())
          );
          
          return {
            ...prev,
            [tabTitle]: [...filteredChanges, changeDescription]
          };
        });
      }

      updatedPermissions[menuIndex] = {
        ...updatedPermissions[menuIndex],
        [permission.toLowerCase()]: newValue,
      };
    }

    setRolePermissions(updatedPermissions);
    setModifiedPermissions(prev => {
      const newSet = new Set(prev);
      newSet.add(menuId);
      return newSet;
    });
  };

  const handleSave = () => {
    const permissions = rolePermissions.filter((permission) => modifiedPermissions.has(permission.recordId));
    const formattedPermissions = permissions.map((permission) => ({
      record_id: permission.recordId,
      module_id: permission.moduleId,
      isview: permission.view,
      iswrite: permission.write,
      isdelete: permission.delete,
      isdownload: permission.download,
      name: permission.name,
      module_name: permission.module,
      type: permission.type,
      access_id: permission.accessId,
      tableName: "AccessLines",
    }));
    
    setChangedPermissions(formattedPermissions);
    setConfirmModalVisible(true);
  };

  const handleConfirmSave = async () => {
    try {
      setLoading(true);
      const { access_token } = getOAuthHeaders();
      const appId = localStorage.getItem("appId");

      const response = await Axios.post(
        genericUrl,
        {
          query: `
            mutation {
              upsertRolePermissions(
                roleId: "${selectedRoleId}",
                roleJson: ${JSON.stringify(JSON.stringify(changedPermissions))}
              ) {
                title
                message
              }
            }
          `,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
            appId,
          },
        }
      );
      
      if (response.data?.data?.upsertRolePermissions?.title === "Success") {
        setModifiedPermissions(new Set());
        message.success(response.data?.data?.upsertRolePermissions?.message);
        setTabWiseChanges(new Set());
      }
      setConfirmModalVisible(false);
    } catch (error) {
      console.error("Error saving permissions:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectAll = (permission, checked, currentTabMenus, tabTitle) => {
    setRolePermissions((prev) => {
      const updatedPermissions = [...prev];
      
      currentTabMenus.forEach((menu) => {
        if (!menuSearch || menu.title.toLowerCase().includes(menuSearch.toLowerCase())) {
          const menuIndex = updatedPermissions.findIndex((p) => p.recordId === menu.id);
          if (menuIndex !== -1) {
            const oldValue = updatedPermissions[menuIndex][permission.toLowerCase()];
            const newValue = checked ? "Y" : "N";

            if (oldValue !== newValue) {
              setTabWiseChanges(prevChanges => {
                const menuName = updatedPermissions[menuIndex].name;
                
                if (!prevChanges[tabTitle]) {
                  prevChanges[tabTitle] = [];
                }
                
                const changeDescription = {
                  menuName,
                  permission: permission.toLowerCase(),
                  type: checked ? "added" : "removed",
                  description: `${checked ? "Added" : "Removed"} ${permission.toLowerCase()} permission for ${menuName}`
                };
                
                // Remove any existing changes for the same menu and permission
                const filteredChanges = prevChanges[tabTitle]?.filter(
                  change => !(change.menuName === menuName && change.permission === permission.toLowerCase())
                ) || [];
                
                return {
                  ...prevChanges,
                  [tabTitle]: [...filteredChanges, changeDescription]
                };
              });
            }

            updatedPermissions[menuIndex] = {
              ...updatedPermissions[menuIndex],
              [permission.toLowerCase()]: newValue,
            };
          }
          setModifiedPermissions((prev) => {
            const newSet = new Set(prev);
            newSet.add(menu.id);
            return newSet;
          });
        }
      });

      return updatedPermissions;
    });
  };
  const renderPermissionRow = (menu, tabTitle) => {
    if (menuSearch && !menu.title.toLowerCase().includes(menuSearch.toLowerCase())) {
      return null;
    }

    const permissions = ["View", "Write", "Delete", "Download"];
    const currentPermissions = rolePermissions.find((p) => p.recordId === menu.id) || {};

    return (
      <Row
        key={menu.id}
        align="middle"
        style={{
          marginBottom: "10px",
          padding: "10px 0",
          borderBottom: "1px solid #ddd",
        }}
      >
        <Col span={6}>{menu.title}</Col>
        <Col span={18}>
          <Row gutter={16}>
            {permissions.map((permission) => (
              <Col key={permission} span={6} style={{ textAlign: "center" }}>
                <Checkbox 
                  checked={currentPermissions[permission.toLowerCase()] === "Y"} 
                  onChange={(e) => handlePermissionChange(menu.id, permission, e.target.checked, tabTitle)}
                />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} className="spinLoader" spin />} spinning={loading}>
      <div style={{ height: "83vh", display: "flex", flexDirection: "column" }}>
      <Row align="middle" justify="space-between">
          <Typography.Title level={3}>Manage Role Permissions</Typography.Title>
          {modifiedPermissions.size > 0 && (
            <div>
              <Button
                type="primary"
                onClick={handleSave}
                style={{
                  marginRight: "10px",
                  borderRadius: "4px",
                  fontFamily: "Inter",
                  fontWeight: 700,
                }}
              >
                Save
              </Button>
              <Button
                onClick={() => {
                  setShowData(false);
                  setModifiedPermissions(new Set());
                }}
                style={{
                  borderRadius: "4px",
                  fontFamily: "Inter",
                  fontWeight: 700,
                }}
              >
                Cancel
              </Button>
            </div>
          )}
        </Row>
        <Scrollbars
          hidden={false}
          hideTracksWhenNotNeeded={true}
          thumbSize={90}
          renderView={renderView}
          renderThumbHorizontal={renderThumb}
          renderThumbVertical={renderThumb}
        >
          <Card bordered={false}>
            <Form layout="vertical" style={{ marginBottom: "20px" }}>
              <Row gutter={16}>
                <Col span={6}>
                  <Form.Item label={<Typography.Text strong>Role</Typography.Text>}>
                    <Select
                      showSearch
                      placeholder="Select Role"
                      value={selectedRole}
                      onChange={handleRoleChange}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {roles.map((role, index) => (
                        <Select.Option key={index} value={role["3AAD9D715B3441F0B69205F1300FDE7B"]}>
                          {role["3AAD9D715B3441F0B69205F1300FDE7B"]}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label={<Typography.Text strong>Application</Typography.Text>}>
                    <Select
                      showSearch
                      placeholder="Select Application"
                      value={applications[selectedAppIndex]?.app_id}
                      onChange={(value) => {
                        const index = applications.findIndex((app) => app.app_id === value);
                        setSelectedAppIndex(index);
                        setShowData(false); // Reset display when application changes
                      }}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {applications.map((app, index) => (
                        <Select.Option key={index} value={app.app_id}>
                          {app.app_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Form.Item label=" ">
                    <Button
                      type="primary"
                      onClick={handleGoClick}
                      disabled={!selectedRole || !applications[selectedAppIndex]?.app_id}
                      style={{
                        borderRadius: "4px",
                        fontFamily: "Inter",
                        fontWeight: 700,
                      }}
                    >
                      Go
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>

            {showData && menuStructure.length > 0 && (
                    <>
                      <Row gutter={16}>
                        <Col span={6}>
                        <Input
                        prefix={<SearchOutlined />}
                        placeholder="Search Menu"
                        onChange={(e) => setMenuSearch(e.target.value)}
                        style={{ marginBottom: 16,borderRadius:'4px' }}
                      />
                        </Col>
                      </Row>
                      <Tabs activeKey={activeTabKey} onChange={setActiveTabKey}>
      {menuStructure.map((section, index) => (
        <TabPane tab={section.title} key={index.toString()}>
          {section.children?.length > 0 ? (
            <>
              <Row style={{ fontWeight: "bold", marginBottom: "10px" }}>
                <Col span={6}>Menu</Col>
                <Col span={18}>
                  <Row gutter={16} justify="center">
                    {["View", "Write", "Delete", "Download"].map((header) => (
                      <Col key={header} span={6} style={{ textAlign: "center" }}>
                        <Checkbox
                          onChange={(e) => handleSelectAll(
                            header, 
                            e.target.checked, 
                            section.children,
                            section.title // Pass the tab title here
                          )}
                        >
                          {header}
                        </Checkbox>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
              {section.children.map((menu) => renderPermissionRow(menu, section.title))}
            </>
          ) : (
            <div style={{ textAlign: "center", padding: "20px", color: "#888" }}>
              No menus available for this tab.
            </div>
          )}
        </TabPane>
      ))}
    </Tabs>

                    </>
                  )}
          </Card>
        </Scrollbars>
        <Modal
          title="Confirm Permission Changes"
          visible={confirmModalVisible}
          onOk={handleConfirmSave}
          onCancel={() => setConfirmModalVisible(false)}
          width={600}
          footer={[
            <Button key="cancel" onClick={() => setConfirmModalVisible(false)} 
              style={{borderRadius:"4px", fontFamily: "Inter", fontWeight: 700}}>
              Cancel
            </Button>,
            <Button key="save" type="primary" onClick={handleConfirmSave} 
              style={{borderRadius:"4px", fontFamily: "Inter", fontWeight: 700}}>
              Save Changes
            </Button>
          ]}
        >
          <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            {Object.entries(tabWiseChanges).map(([tabTitle, changes]) => (
              <div key={tabTitle} style={{ backgroundColor: '#f5f5f5', padding: '16px', borderRadius: '8px' }}>
                <Text strong style={{ display: 'block', marginBottom: '8px' }}>
                  {tabTitle}
                </Text>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                  {changes.map((change, index) => (
                    <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                      {change.type === 'added' ? (
                        <CheckCircleOutlined style={{ color: 'green' }} />
                      ) : (
                        <CloseCircleOutlined style={{ color: 'red' }} />
                      )}
                      <Text>{change.description}</Text>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </Modal>
      </div>
    </Spin>
  );
};

export default WindowComponent;