import React, { useEffect, useState } from "react";
import { getComboFillForProcess, getProcessDefinition,executeProcessButton, getProcessDefinitionData, getProcessParamJson, getProcessParamData } from "../../services/generic";
import { useParams } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";import moment from "moment";
import { useGlobalContext,useWindowContext } from "../../lib/storage";
import { DatePicker, Select, Button, Table, Tabs, Row, Skeleton, message,Collapse,Modal,Col,Input,Form,Spin,Space } from "antd";
import ThemeJson from "../../constants/UIServer.json"
import Close from "../../assets/images/close-x.svg";
import Scrollbars from "react-custom-scrollbars";
import ProcessField from "../recordWindow/ProcessField";
import RecordTable from "../recordWindow/RecordTable";
import useDebounce from "../../lib/hooks/useDebounce";
import dayjs from "dayjs";

const { RangePicker } = DatePicker; // For date range selection
const { Option } = Select;
const { TabPane } = Tabs;
const { Panel } = Collapse;

const renderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    backgroundColor: "#c1c1c1",
    borderRadius: "5px",
    width: "8px",
  };
  return <div style={{ ...style, ...thumbStyle }} {...props} />;
};

const renderView = ({ style, ...props }) => {
  const viewStyle = {
    color: "#00000",
  };
  return <div className="box" style={{ ...style, ...viewStyle }} {...props} />;
};

const ProcessWindow = () => {
    const [form] = Form.useForm();
  const Themes = ThemeJson;
  const { globalStore } = useGlobalContext();
  const userPreferences = globalStore.userPreferences;
  const dateFormat = userPreferences.dateFormat;
  // const { windowStore } = useWindowContext();
  // const windowDefinition = { ...windowStore.windowDefinition };
  const [reload,setReload] = useState(false)
  const [selectedAction,setSelectedAction] = useState(null)
  const [selectedRecordsData, setSelectedRecordsData] = useState({});
  const [activeTab, setActiveTab] = useState(null); // State for active tab
  const [activeTabData, setActiveTabData] = useState(null); 
  const [selectedRows, setSelectedRows] = useState([]);
  const [processData, setProcessData] = useState([]);
  const [processDefinition, setProcessDefinition] = useState();
  const [processParamsData, setProcessParamsData] = useState({});
  const [dropdownDetails, setDropdownDetails] = useState([]);
  const [selectedDate, setSelectedDate] = useState([moment().subtract(1, "month"), moment()]);
  const [businessUnit, setBusinessUnit] = useState([]);
  const [visible, setVisible] = React.useState(false);
    const [formFields, setFormFields] = useState([]);
    const [formLineFields, setFormLineFields] = useState([]);
  const [titleButtonProcess, setTitleButtonProcess] = useState("");
    const [loadingModal, setLoadingModal] = useState(false);
  const [badges, setBadges] = useState([]);
  const [tabs, setTabs] = useState([])
  const [loading, setLoading] = useState(false); // For loading state

  const { processId } = useParams();

  const handleOk = async () => {
    try {
      await form.validateFields();
      form.submit();
    } catch (error) {
      console.log(error,"---ereie")
    }
  };

  const handleCancel = () => {
    //datRows.current = selectedRowKeys;
    setVisible(false);
    setSearchInputs({})
    setSelectedRecordsData({});
  };

  // Fetch Process Definition
  useEffect(() => {
    const definitionProcess = async () => {
      try {
        const processDefinition = await getProcessDefinition(processId);
        // console.log(JSON.parse(processDefinition));
        setProcessDefinition(JSON.parse(processDefinition));
      } catch (error) {
        console.error("Error fetching process definition:", error);
      }
    };

    definitionProcess();
  }, [processId]);

  useEffect(() => {
    if (processData && processDefinition) {
      const badgesArray = processDefinition.Data.filter(item => item.type === "Badge").map(badge => ({
        name: badge.name,
        fields: JSON.parse(badge.fields)
      }));
      const tabsArray = processDefinition.Data.filter(item => item.type === "Tab").map(tab => ({
        name: tab.name,
        fields: tab.fields,
        id:tab.id
      }));
      setBadges(badgesArray);
      setTabs(tabsArray);
      setActiveTab(tabsArray[0].name)
    }
  }, [processData, processDefinition]);

  // Fetch Dropdown Data
  const onDropDownSelect = async (dashboard, id, value) => {
    try {
      const getFilterData = await getComboFillForProcess(dashboard, id, value);
      if (getFilterData && typeof getFilterData[Symbol.iterator] === "function") {
        setDropdownDetails([...getFilterData]);
      } else {
        console.error("Error getting dropdown details");
      }
    } catch (error) {
      console.error("Error in onDropDownSelect:", error);
    }
  };

  // Handle Apply Button Click
  const handleApply = async () => {
    setLoading(true); // Set loading to true when applying filters
    try {
      const payload = {};
      setSelectedRecordsData(null)
      setActiveTab(null)
      setSelectedRows([])
      setActiveTabData(null)
      // Loop through processDefinition.Filters to dynamically set payload keys
      processDefinition?.Filters.forEach((filter) => {
        if (filter.type === "DateRange") {
          payload[filter.columnName] = selectedDate.map((date) => moment(date).format("YYYY-MM-DD"));
        } else if (filter.type === "MultiSelector") {
          payload[filter.columnName] = businessUnit; // Assuming businessUnit holds selected values
        }
      });

      // console.log("Payload for fetchProcessData:", payload);
      const stringifiedJSON = JSON.stringify(payload);
      const jsonToSend = stringifiedJSON.replace(/"/g, '\\"');
      // console.log(jsonToSend);
      // Fetch process data using a service
      const processData = await getProcessDefinitionData(processId, jsonToSend);
      console.log("Fetched Process Data:", processData);
      setProcessData(JSON.parse(processData.data)); // Assuming the response data is in 'data'
    } catch (error) {
      console.error("Error in handleApply:", error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  {/* Render Badges */}
  const renderBadges = () => {
    if (loading) return <Skeleton active />;
    if (!processDefinition || !processData) return <div>No Badges Found</div>;

    const badges = processDefinition.Data.filter(item => item.type === "Badge");

    return (
      <Row gutter={[16, 16]}>
        {badges.map((badge, index) => {
          const badgeValue = processData[badge.id];
          return (
            <div key={index} style={{
              background: "#fff",
              padding: "1rem",
              borderRadius: "8px",
              border:"1px solid #dfdfdf",
              minWidth: "200px",
              boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
            }}>
              <span style={{fontFamily:"Inter",fontWeight:500,fontSize:"12.6px"}}>{badge.name}</span>
              <br/>
              <span style={{ fontSize: "24px", fontWeight: 700 }}>{badgeValue}</span>
            </div>
          );
        })}
      </Row>
    );
  };

  const handleRowSelect = (rowData) => {
    setSelectedRows((prevSelectedRows) => {
      const isSelected = prevSelectedRows.some(row => row === rowData);
      if (isSelected) {
        return prevSelectedRows.filter(row => row !== rowData);
      } else {
        return [...prevSelectedRows, rowData];
      }
    });
  };

  const onFinish = async (values) => {
    setLoadingModal(true);
    Object.entries(values).map(([key, value]) => {
      if (value === true) {
        values[key] = "Y";
      }
      if (value === false) {
        values[key] = "N";
      }
      if (typeof value === "number") {
        values[key] = `${value}`;
      }
      if (dayjs.isDayjs(value)) {
        values[key] = `${value.format("YYYY-MM-DD HH:mm:ss")}`;
      }
      if (value === "") {
        values[key] = null;
      }
      if (value === undefined) {
        values[key] = null;
      }
      return null;
    });

    let getAllTablesValues = {};
    Object.keys(processParamsData).forEach(function (key) {
      let valObj = processParamsData[key];
      if (typeof valObj === "object" && valObj !== null) {
        getAllTablesValues[key] = valObj;
      }
    });

    let mandatoryViolated = false;
   Object.entries(getAllTablesValues).forEach(([aKey, aValue]) => {
         const fieldParamJson = formLineFields[formLineFields.findIndex((ffl) => ffl.column_name === aKey)];
         aValue.forEach((bObj, bIndex) => {
           Object.entries(bObj).forEach(([cKey, cValue]) => {
             if (cValue === true) {
               getAllTablesValues[aKey][bIndex][cKey] = "Y";
             }
             if (cValue === false) {
               getAllTablesValues[aKey][bIndex][cKey] = "N";
             }
             if (typeof cValue === "number") {
               getAllTablesValues[aKey][bIndex][cKey] = `${cValue}`;
             }
             if (dayjs.isDayjs(cValue)) {
               getAllTablesValues[aKey][bIndex][cKey] = `${cValue.format("YYYY-MM-DD HH:mm:ss")}`;
             }
             if (cValue === "") {
               getAllTablesValues[aKey][bIndex][cKey] = null;
             }
             if (cValue === undefined) {
               getAllTablesValues[aKey][bIndex][cKey] = null;
             }
   
             if (!cValue) {
               const fieldParamJsonIndex = fieldParamJson.fields.findIndex((ffl) => ffl.field_name === cKey);
               if (fieldParamJsonIndex >= 0 && fieldParamJson.fields[fieldParamJsonIndex].mandatory === "Y") {
                 mandatoryViolated = true;
               }
             }
           });
         });
       });

    if (!mandatoryViolated) {
      let completeData = Object.assign({}, values, getAllTablesValues);
       
      const payload = {};
      processDefinition?.Filters.forEach((filter) => {
        if (filter.type === "DateRange") {
          payload[filter.columnName] = selectedDate.map((date) => moment(date).format("YYYY-MM-DD"));
        } else if (filter.type === "MultiSelector") {
          payload[filter.columnName] = businessUnit; // Assuming businessUnit holds selected values
        }
      });
  
      payload[activeTab]= selectedRows;
      Object.assign(payload, completeData);
 
      const stringifiedJSON = JSON.stringify(payload);
      const jsonToSend = stringifiedJSON.replace(/"/g, '\\"');
      // console.log(jsonToSend)
      try {
        const executeProcessData = await executeProcessButton(processId,selectedAction.id,selectedRows.length === 1 && selectedRows.length === 1 && activeTabData?.recordId ?selectedRows[0][activeTabData?.recordId]:null, jsonToSend);
        if(executeProcessData.messageCode == 200){
          setLoading(true)
          setLoadingModal(false);
          setVisible(false)
          setSelectedRows([])
          message.success(executeProcessData.message)
  
        // Loop through processDefinition.Filters to dynamically set payload keys
        // processDefinition?.Filters.forEach((filter) => {
        //   if (filter.type === "DateRange") {
        //     payload[filter.columnName] = selectedDate.map((date) => moment(date).format(dateFormat));
        //   } else if (filter.type === "MultiSelector") {
        //     payload[filter.columnName] = businessUnit; // Assuming businessUnit holds selected values
        //   }
        // });
  
        // console.log("Payload for fetchProcessData:", payload);
        const stringifiedJSON = JSON.stringify(payload);
        const jsonToSend = stringifiedJSON.replace(/"/g, '\\"');
        const processData = await getProcessDefinitionData(processId, jsonToSend);
        // console.log("Fetched Process Data:", processData);
        setProcessData(JSON.parse(processData.data)); 
        }else{
          message.error(executeProcessData.message)
        }
        // Send the mutation request
      } catch (error) {
        setLoadingModal(false);
        console.error("Error executing process action:", error);

      }
      setLoading(false)
     
    } else {
      message.warning("Please input proper values !");
    }
  };
  
  const handleProcessAction = async (action) => {
    const tabs = processDefinition.Data.filter(item => item.type === "Tab");
    const activeTabData = tabs.find((tab) => tab.name === activeTab); 
    console.log(activeTabData,selectedRows[0][activeTabData?.recordId])
    setActiveTabData(activeTabData)
   if(action.showProcessPopup === "Y"){
    setSelectedAction(action)
    const param = {}
    param[activeTab]= selectedRows;
    const stringifiedJSON = JSON.stringify(param);
    const jsonToSend = stringifiedJSON.replace(/"/g, '\\"');
    const processData = await getProcessParamJson(null,null,action.processId);
    const paramData = await getProcessParamData(null, null, selectedRows.length === 1 ?selectedRows[0][activeTabData?.recordId]:null, action.processId,jsonToSend);
    setProcessParamsData(paramData)
    // console.log(paramData)
    // console.log(processData)
    let parameterArrayForGrid = [];
      let parameterArray = [];
      let btnProcessData = processData.parameters;
      for (let i = 0; i < btnProcessData.length; i += 1) {
        if (btnProcessData[i].type === "Form") {
          parameterArrayForGrid.push(btnProcessData[i]);
        } else {
          parameterArray.push(btnProcessData[i]);
        }
      }
      setTitleButtonProcess(processData.name);
      for (let index = 0; index < parameterArray.length; index++) {
        parameterArray[index]["ad_field_id"] = parameterArray[index].column_name;
        parameterArray[index]["isreadonly"] = parameterArray[index].readonly;
        parameterArray[index]["column_type"] = parameterArray[index].type;
        parameterArray[index]["name"] = parameterArray[index].display_name;
      }
       
      setFormFields(parameterArray);
  
      setFormLineFields(parameterArrayForGrid);
      setVisible(true)
      

   }else{
    setLoading(true)
    if (selectedRows.length === 0) return;
    const payload = {};
      // Loop through processDefinition.Filters to dynamically set payload keys
    processDefinition?.Filters.forEach((filter) => {
      if (filter.type === "DateRange") {
        payload[filter.columnName] = selectedDate.map((date) => moment(date).format("YYYY-MM-DD"));
      } else if (filter.type === "MultiSelector") {
        payload[filter.columnName] = businessUnit; // Assuming businessUnit holds selected values
      }
    });

    payload[activeTab]= selectedRows;
    const stringifiedJSON = JSON.stringify(payload);
    const jsonToSend = stringifiedJSON.replace(/"/g, '\\"');
    // console.log(jsonToSend)
    try {
      const executeProcessData = await executeProcessButton(processId,action.id, jsonToSend);
      if(executeProcessData.messageCode == 200){
        setSelectedRows([])
        message.success(executeProcessData.message)
        const payload = {};

      // Loop through processDefinition.Filters to dynamically set payload keys
      processDefinition?.Filters.forEach((filter) => {
        if (filter.type === "DateRange") {
          payload[filter.columnName] = selectedDate.map((date) => moment(date).format(dateFormat));
        } else if (filter.type === "MultiSelector") {
          payload[filter.columnName] = businessUnit; // Assuming businessUnit holds selected values
        }
      });

      // console.log("Payload for fetchProcessData:", payload);
      const stringifiedJSON = JSON.stringify(payload);
      const jsonToSend = stringifiedJSON.replace(/"/g, '\\"');
      const processData = await getProcessDefinitionData(processId, jsonToSend);
      // console.log("Fetched Process Data:", processData);
      setProcessData(JSON.parse(processData.data)); 
      }else{
        message.error(executeProcessData.message)
      }
      // Send the mutation request
    } catch (error) {
      console.error("Error executing process action:", error);
    }
    setLoading(false)
    }
  };
  

  {/* Render Tabs */}
  const renderTabs = () => {
    if (loading) return <Skeleton active />;
    if (!processDefinition || !processData) return <div>No Data Available</div>;
  
    const tabs = processDefinition.Data.filter(item => item.type === "Tab");
  
    return (
      <>
        {processDefinition?.Process &&processDefinition?.Process.length > 0 && (
           <div
           style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            paddingBottom: "7px",
            justifyContent: "flex-end",
            alignItems: "center", 
            width: "100%", 
          }}
         >
        {processDefinition?.Process?.map((button) => (
        <Button
          key={button.id}
          type="primary"
          disabled={selectedRows.length === 0}
          onClick={() => handleProcessAction(button)}
          style={{ marginTop: "0.5rem",fontFamily:"Roboto",color:"#0C173A",fontSize:"14px",background:"#B5C3F0",fontWeight:600,borderRadius:"5px",border: '1px solid #B5C3F0',letterSpacing:"0.5px" }} 
        >
          {button.name}
        </Button>
      ))}
      </div>
        )}
        <Tabs defaultActiveKey={tabs[0].name} onChange={(key) => setActiveTab(key)} tabBarStyle={{background:"white"}} >
          {tabs.map((tab, index) => {
            const tableData = processData[tab.id] || [];
            const isAllSelected = selectedRows.length === tableData.length;

            const toggleSelectAll = (tableData) => {
              if (selectedRows.length === tableData.length) {
                setSelectedRows([]); // Deselect all
              } else {
                setSelectedRows([...tableData]); // Store all row data
              }
            };
  
            return (
              <TabPane tab={tab.name} key={tab.name}>
               <div style={{ overflowX: 'auto' }}>
                <div style={{ maxHeight: '40vh', overflowY: 'auto' }}> {/* Set a max height for the table */}
                    <table
                    style={{
                        width: '100%',
                        borderCollapse: 'collapse',
                        boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                        fontFamily:"Inter",
                    }}
                    >
                    <thead style={{fontFamily:"Inter",color:"#71717a",fontWeight:500,borderBottom:"1px solid #dfdfdf"}}> 
                        <tr>
                        <th style={{
                              position: 'sticky',
                              top: 0, // This makes the header stick to the top
                              backgroundColor: '#fff', // Ensure header background is white to cover content below it
                              zIndex: 1, // Makes sure header stays on top
                              borderBottom: '1px solid #ddd',
                              padding: '8px',
                              textAlign: 'left',
                              }}>
                          <input type="checkbox" checked={isAllSelected} onChange={() => toggleSelectAll(tableData)} />
                        </th>
                        {JSON.parse(tab.fields)
                        .filter(field => field.isdisplay !== 'N')
                        .map((field, idx) => (
                            <th
                            key={idx}
                            style={{
                                position: 'sticky',
                                top: 0, // This makes the header stick to the top
                                backgroundColor: '#fff', // Ensure header background is white to cover content below it
                                zIndex: 1, // Makes sure header stays on top
                                borderBottom: '1px solid #ddd',
                                padding: '8px',
                                textAlign: 'left',
                              }}
                            >
                            {field.name}
                            </th>
                        ))}
                        </tr>
                    </thead>
                    <tbody style={{font:" Inter", fontSize:"13px",fontWeight:400}}>
                    {loading ? (
                        <tr>
                            <td colSpan={JSON.parse(tab.fields).filter(field => field.isdisplay !== 'N').length} style={{ textAlign: "center", padding: "20px" }}>
                            <LoadingOutlined className="spinLoader" style={{ fontSize: "52px" }} spin />
                            </td>
                        </tr>
                        ) : tableData.length > 0 ? (
                        tableData.map((row, rowIndex) => (
                            <tr style={{backgroundColor: '#fff'}} key={rowIndex}>
                              <td style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>
                              <input type="checkbox" checked={selectedRows.includes(row)} onChange={() => handleRowSelect(row)} />
                            </td>

                            {JSON.parse(tab.fields)
                            .filter(field => field.isdisplay !== 'N')
                            .map((field, colIndex) => (
                                <td
                                key={colIndex}
                                style={{
                                    borderBottom: '1px solid #ddd',
                                    padding: '8px',
                                    textAlign: 'left',
                                }}
                                >
                                {row[field.columnname] || '-'}
                                </td>
                            ))}
                            </tr>
                        ))
                        ) : (
                        <tr>
                             <td
                                colSpan={JSON.parse(tab.fields).length}
                                style={{
                                    textAlign: "center",
                                    padding: "20px",
                                    fontWeight: "bold",
                                    color: "#71717a",
                                }}
                                >
                                No Data Available
                                </td>
                        </tr>
                        )}
                    </tbody>
                    </table>
                </div>
                </div>

              </TabPane>
            );
          })}
        </Tabs>
      </>
    );
  };

  const checkProcessDisplayLogic = (field) => {
    if (field?.displaylogic) {
      let string = field.displaylogic;
      const matches = string.match(/@([^@]+)@/g); // Match all occurrences of text between @ symbols

       const keys = matches ? matches.map(match => match.slice(1, -1)) : []; // Remove @ symbols
      const values = form.getFieldsValue();
      
      keys.map((k) => {
       
        let actualDataValue = values[k] ?? processParamsData[k];
       
        if (typeof actualDataValue === "string" && isNaN(actualDataValue)) {
          actualDataValue = `'${actualDataValue}'`;
        }
     
        if (typeof actualDataValue === "boolean") {
          if (actualDataValue) {
            actualDataValue = `'Y'`;
          } else {
            actualDataValue = `'N'`;
          }
        }
 
        const actualData = actualDataValue;
        
        const stringToUpdate = "@" + k + "@";
        return (string = string.replaceAll(stringToUpdate, actualData));
      });

      string = string.replaceAll("=", "==");
      string = string.replaceAll("<==", "<=");
      string = string.replaceAll(">==", ">=");
      string = string.replaceAll("&", "&&");
      string = string.replaceAll("|", "||");
      string = string.replaceAll("====", "===");
      string = string.replaceAll("&&&&", "&&");
      string = string.replaceAll("||||", "||");
      // console.log(string,"----string")
      let logicState;
      try {
        // eslint-disable-next-line
        logicState = eval(string);
      } catch (error) {
        console.error("Invalid Display Logic Condition: ", string);
        logicState = false;
      }

      return logicState;
    } else {
      return true;
    }
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
      <Space style={{marginBottom:'5px'}}>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
        >
          Reset
        </Button>
        <Button
          onClick={() => {
            confirm({ closeDropdown: true });
            // document.getElementById("filterText").value = "";
            handleReset(clearFilters)
          }}
          size="small"
        >
          Ok
        </Button>
      </Space>
      <br/>
        <Input
          value={selectedKeys[0]}
          onChange={e => { setSelectedKeys(e.target.value ? [e.target.value] : []) }}
          style={{ marginBottom: 3, width: 200 }}
        />
                
    </div>
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
  });

  const handleSave = (row) => {
    let processLocalData = processParamsData;
   
    const newData = processLocalData[row["tableName"]];

    const index = newData.findIndex((item) => row.key === item.key);

    const item = newData[index];
    
    newData.splice(index, 1, { ...item, ...row });
    
    let tempTableData = processLocalData[row["tableName"]];
    let tempTableName = row["tableName"];

    setProcessParamsData({
      ...processParamsData,
      [tempTableName]: [...tempTableData],
    });
    setReload(true)
  };

  const batchSize = 30; // Load 30 records initially
  const [displayedData, setDisplayedData] = useState({}); // Track displayed records for each column
  // const [loadingModal, setLoadingModal] = useState(false); // Spinner state

  useEffect(() => {
    if (visible) {
      // Initially load the first batch of records when the modal is visible
      if(!reload){
      setLoadingModal(true);
      }
      const initialDisplayedData = {};

      let hasRecords = false; // Track if there are any records to load

      formLineFields.forEach((collaps) => {
        const columnData = processParamsData[collaps.column_name] || [];
        if (columnData.length > 0) {
          hasRecords = true; // Indicate that there is at least one record
        }
        initialDisplayedData[collaps.column_name] = columnData.slice(0, batchSize); // Load initial batch
      });

      setDisplayedData(initialDisplayedData);

      if (hasRecords) {
        // Load remaining records in a progressive manner after the modal is opened
        setTimeout(() => {
          loadRemainingRecords();
        }, 500); // Delay to simulate modal being opened before loading remaining data
      } else {
        // No records, so stop the loading spinner immediately
        setLoadingModal(false);
        setReload(false)
      }
    }
  }, [visible, processParamsData]);

  // Function to load remaining records in batches
  const loadRemainingRecords = () => {
    formLineFields.forEach((collaps) => {
      const columnData = processParamsData[collaps.column_name] || [];
      const totalRecords = columnData.length;

      if (totalRecords > batchSize) {
        const remainingData = columnData.slice(batchSize);

        // Progressively load the remaining data
        setTimeout(() => {
          setDisplayedData((prev) => ({
            ...prev,
            [collaps.column_name]: [...prev[collaps.column_name], ...remainingData]
          }));
          setLoadingModal(false); // Hide spinner once all data is loaded
        }, 500); // Delay to simulate progressive loading
      } else {
        setLoadingModal(false); // Hide spinner if no additional data is needed
      }
    });
  };

  const [searchInputs, setSearchInputs] = useState({}); // Store search input for each table
  const debouncedSearchInputs = useDebounce(searchInputs, 500); // Debounce the inputs
  
  useEffect(() => {
    const filteredData = {};
  
    // If no search input is provided, reset displayed data for each table
    formLineFields.forEach((collaps) => {
      const columnName = collaps.column_name;
      const searchInput = debouncedSearchInputs[columnName]?.toLowerCase() || ""; // Get debounced input for this table
      const columnData = processParamsData[columnName] || [];
  
      if (!searchInput) {
        // Reset to initial batch size if no input
        filteredData[columnName] = columnData.slice(0, batchSize);
      } else {
        // Filter the data for this specific table
        filteredData[columnName] = columnData.filter((record) =>
          Object.values(record).some((value) =>
            String(value).toLowerCase().includes(searchInput)
          )
        );
      }
    });
  
    setDisplayedData(filteredData); // Update displayed data for all tables
  }, [debouncedSearchInputs, processParamsData]);
  
  const handleSearchChange = (value, columnName) => {
    setSearchInputs((prev) => ({
      ...prev,
      [columnName]: value, // Update the search input for the specific table
    }));
  };
  
  const handleFormValuesChange = (allValues) => {
    console.log("Updated Form Values:", allValues);
  
    const updatedFields = formFields.map((field) => {
      const shouldDisplay = checkProcessDisplayLogic(field);
      return {
        ...field,
        shouldRender: shouldDisplay,
      };
    });
  
    // Update the formFields state to reflect the new logic
    setFormFields(updatedFields);
  };
  
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center", // Vertically center items
        }}
      >
        {/* Process Name */}
        <span style={{ fontFamily:"Inter",fontWeight:550,fontSize:"23px",color:"#161537" }}>
          {processDefinition?.name || <Skeleton.Input style={{ width: 200 }} active />}
        </span>

        {/* Filters and Button */}
        <div style={{ display: "flex", gap: "1rem", alignItems: "end" }}>
          {processDefinition?.Filters.map((filter, index) => {
            if (filter.type === "DateRange") {
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start", // Align labels and inputs properly
                  }}
                >
                  <label style={{ marginBottom: "0.25rem",fontFamily:"Inter",fontSize:"12px",fontWeight:500 }}>{filter.displayName}</label>
                  <RangePicker
                    defaultValue={[moment().subtract(1, "month"), moment()]} // Last 1 month range
                    format={dateFormat}
                    onCalendarChange={(val) => {
                      if (val) {
                        const arr = val.map((date) => moment(date).format(dateFormat));
                        // console.log("Selected Dates:", arr);
                        setSelectedDate(arr);
                      }
                    }}
                  />
                </div>
              );
            }

            if (filter.type === "MultiSelector") {
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start", // Align labels and inputs properly
                  }}
                >
                  <label style={{ marginBottom: "0.25rem",fontFamily:"Inter",fontSize:"12px",fontWeight:500 }}>{filter.displayName}</label>
                  <Select
                    mode="multiple"
                    maxTagCount={1}
                    listHeight={100}
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ minWidth: "200px",borderRadius:"5px" }}
                    onSearch={(value) => onDropDownSelect(processId, filter.id, value)}
                    onFocus={() => onDropDownSelect(processId, filter.id)}
                    placeholder={`Select ${filter.displayName}`}
                    onChange={(e) => {
                      setBusinessUnit(e);
                    }}
                  >
                    {dropdownDetails?.map((data) => (
                      <Option key={JSON.parse(data).recordid} value={JSON.parse(data).recordid}>
                        {JSON.parse(data).name}
                      </Option>
                    ))}
                  </Select>
                </div>
              );
            }

            return null;
          })}

          {/* Button */}
          <Button type="primary" style={{ marginTop: "0.5rem",fontFamily:"Roboto",fontSize:"14px",fontWeight:600,borderRadius:"5px",letterSpacing:"0.5px" }} onClick={handleApply} loading={loading}>
            Apply
          </Button>
        </div>
      </div>
      <div style={{padding:"0.5rem"}}>
        {renderBadges()}
      </div>
      
      <div style={{ marginTop: '1px' }}>
        {renderTabs()}
      </div>
      <Modal
          title={<><h2 style={{fontWeight:'bold',fontStyle:'normal',float:'left',marginLeft:"-7px"}}>{titleButtonProcess}</h2> </>}
            className="tabModal"
           closeIcon={<img src={Close} alt="" style={{height:"4vh"}}/>}  
          visible={visible}
          getContainer={false}
          onOk={handleOk}
          onCancel={handleCancel}
          style={{top:"-17px"}}
          bodyStyle={{padding:0}}
          centered
          width="1000px"
          maskClosable={false}
          destroyOnClose={true}
          footer={[
            <span
             style={{color:Themes.appTheme.primaryColor,fontWeight:700,cursor:'pointer'}}
              onClick={handleCancel}
            >
              Cancel
            </span>,
            <Button style={{ backgroundColor:Themes.appTheme.primaryColor, color: "white", width: "88px", height: "36px",marginLeft:'31px',fontWeight:700,borderRadius:'4px'}} loading={false} onClick={handleOk}>
              Confirm
            </Button>,
          ]}
        >
          <Spin indicator={<LoadingOutlined className="spinLoader" style={{ marginLeft: "auto", fontSize: "52px" }} spin />} spinning={loadingModal}>
            <Scrollbars
              style={{
                // marginLeft:'1.5px',
                // height:"auto",
                transition: 'height 0.3s',
                boxShadow:" 0px 2px 2px 0px #0000001A"
              }}
              autoHeight
              autoHeightMax={"60vh"}
              hidden={false}
              hideTracksWhenNotNeeded={true}
              universal
              thumbSize={90}
              renderView={renderView}
              renderThumbHorizontal={renderThumb}
              renderThumbVertical={renderThumb}
            >
              <Form
                style={{ paddingBottom: "10px", paddingLeft: "20px", paddingRight: "20px" }}
                form={form}
                preserve={false}
                name="processBtnForm"
                layout="vertical"
                onFinish={onFinish}
                // onValuesChange={(_, allValues) => handleFormValuesChange(allValues)}
              >
                <Row gutter={[24, 24]}>
                {formFields
                  .sort((a, b) => a.sequenceno - b.sequenceno) // Sort fields in ascending order by sequence
                  .map((field, index) => {
                    const shouldRenderTab = 
                      // checkProcessDisplayLogic(field) && 
                      field.displayed === "Y";

                    return shouldRenderTab ? (
                      <Col key={`${index}-${field.parameter_id}`} span={8}>
                        <ProcessField 
                          // windowId={windowDefinition.ad_window_id} 
                          // headerTabId={headerTabId} 
                          field={field} 
                          form={form} 
                          fieldData={processParamsData} 
                          setProcessParamsData={setProcessParamsData} 
                          recordId={selectedRows.length === 1 ?selectedRows[0][activeTabData?.recordId]:null} 
                          // setReload={setReload} 
                        />
                      </Col>
                    ) : null;
                  })}
                </Row>
                <div style={{ paddingTop: "24px" }} />
                <div style={Themes.contentWindow.recordWindow.RecordHeader.formViewButton.actionButtonMenu}>
          
                  {formLineFields .sort((a, b) => a.sequenceno - b.sequenceno) .map((collaps) => {
                    
                    let processParams = processParamsData[collaps.column_name];

                    if (processParams) {
                      for (let index = 0; index < processParams.length; index++) {
                        processParams[index]["tableName"] = collaps.column_name;
                        processParams[index]["key"] = index.toString();
                      }
                    }

                    let colFields = collaps.fields;
                    const tableColumns = [];

                    const sortCollapsFields = colFields.sort(function (a, b) {
                      return a.sequenceno - b.sequenceno;
                    });

                    for (let index = 0; index < sortCollapsFields.length; index++) {
                      sortCollapsFields[index]["mainKey"] = index;
                      if (sortCollapsFields[index]["displayed"] === "Y") {
                        if (sortCollapsFields[index]["type"] === "Selector") {
                          tableColumns.push({
                            title: sortCollapsFields[index]["name"],
                            dataIndex: sortCollapsFields[index]["field_name"],
                            editable: sortCollapsFields[index]["readonly"] === "Y" ? false : true,
                            typeCol: true,
                            mainKey: index,
                          });
                        } else {
                          tableColumns.push({
                            title: sortCollapsFields[index]["name"],
                            dataIndex: sortCollapsFields[index]["field_name"],
                            editable: sortCollapsFields[index]["readonly"] === "Y" ? false : true,
                            typeCol: false,
                            mainKey: index,
                          });
                        }
                      }
                    }

                    const columns = tableColumns.map((col) => {
                      const colDataValues = sortCollapsFields[sortCollapsFields.findIndex((a) => a.mainKey === col.mainKey)];
                      if (!col.editable) {
                        if (col.typeCol) {
                          col.dataIndex = col.dataIndex.concat("_iden");
                        }
                        // Add filter condition for non-editable columns
                        return {
                          ...col,
                          colDataValues,
                          // ...getColumnSearchProps(col.dataIndex),
                        };
                      }

                      if (colDataValues.readonly === "Y") {
                        if (col.typeCol) {
                          col.dataIndex = col.dataIndex.concat("_iden");
                        }
                        return {
                          ...col,
                          colDataValues, // Include colDataValues for readonly columns
                        };
                      }

                      return {
                        ...col,
                        colDataValues,
                        onCell: (record, rowIndex) => ({
                          record,
                          rowIndex: rowIndex,
                          editable: col.editable,
                          dataIndex: col.dataIndex,
                          title: col.title,
                          colData: colDataValues,
                          // handleSave: handleSave,
                          allCols:formLineFields
                        }),
                      };
                    });
                    const columnName = collaps.column_name;
                    // const searchValue = searchInputs[columnName] || "";
                    return (
                      <div style={{ paddingTop: "12px" }}>
                        <Collapse defaultActiveKey={['yourPanelKey']}>
                          <Panel header={collaps.display_name} key="yourPanelKey">
                          <Col span={8}>
                          {/* <label  style={{ fontWeight: 400, fontSize: 12, fontFamily: "Inter", opacity: 0.6 }}>Search Records</label> */}
                          <Input
                              placeholder={`Search ${collaps.display_name}`}
                              // value={searchValue} // Bind search input for this specific table
                              // onChange={(e) => handleSearchChange(e.target.value, columnName)} // Update search input
                              style={{ borderRadius: "5px", marginTop: "4px" }}
                            />
                           </Col>
                           <br/>
                            <RecordTable
                             dataSource={displayedData[collaps.column_name] || []} 
                              columns={columns}
                              selectedRecordsData={selectedRecordsData}
                              setSelectedRecordsData={setSelectedRecordsData}
                             handleSave={handleSave}
                            />
                          </Panel>
                        </Collapse>
                      </div>
                    );
                  })}
                </div>
                <div style={{ paddingTop: "0px" }} />
              </Form>
            </Scrollbars>
          </Spin>
        </Modal>
     </div>
  );
};

export default ProcessWindow;
