import { useState, useEffect } from "react";
import { Card, Button, Typography, Row, Col, Spin, Input, Modal, Form, Select } from "antd";
import MenuDetails from "./MenuDetails";
import Axios from "axios";
import { getOAuthHeaders } from "../../../constants/oAuthValidation";
import { LoadingOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";

const { Search } = Input;

const MenuManagement = () => {
    const [loading, setLoading] = useState(false);
    const [selectedMenu, setSelectedMenu] = useState(null);
    const [menuList, setMenuList] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [storeOptions, setStoreOptions] = useState([]);
    const [form] = Form.useForm();
    const serverUrl = process.env.REACT_APP_serverUrl;
    const genericUrl = process.env.REACT_APP_genericUrl;

    useEffect(() => {
        getRestaurantMenus();
        getStoreOptions();
    }, []);

    const getRestaurantMenus = async (newMenuData) => {
        const { access_token } = getOAuthHeaders();
        setLoading(true);
        try {
            const response = await Axios.post(
                serverUrl,
                {
                    query: `query {
    getRestaurantMenu(limit: 150, offset: 0) {
        name
        isactive
        cWRPCatalogueID
        multiPriceList
        taxConfig
        stores {
            name
            value
            csBunitId
        }
    }
}`
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${access_token}`,
                    }
                }
            );
            if (response?.status === 200) {
                setMenuList(response.data.data.getRestaurantMenu);
                if (newMenuData) {
                    const newMenu = response.data.data.getRestaurantMenu.find(menu => menu.name === newMenuData.name);
                    if (newMenu) setSelectedMenu(newMenu);
                }
            }
        } catch (error) {
            console.error("Error fetching menu:", error);
        } finally {
            setLoading(false);
        }
    };

    const getStoreOptions = async () => {
        const { access_token } = getOAuthHeaders();
        try {
            const response = await Axios.post(
                genericUrl,
                {
                    query: `query {
                        comboFill(tableName:"cs_bunit", pkName:"cs_bunit_id", identifier:"name", whereClause:"isactive='Y'")
                    }`
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${access_token}`,
                    }
                }
            );
            if (response?.status === 200) {
                const options = JSON.parse(response.data.data.comboFill).map(store => ({
                    label: store.name,
                    value: store.recordid
                }));
                setStoreOptions(options);
            }
        } catch (error) {
            console.error("Error fetching stores:", error);
        }
    };

    const handleCreateMenu = async (values) => {
        const { access_token } = getOAuthHeaders();
        setLoading(true);
        try {
            const selectedStores = values.store.map(storeId => ({
                csBunitId: storeId,
                name: storeOptions.find(opt => opt.value === storeId)?.label
            }));

            const response = await Axios.post(
                serverUrl,
                {
                    query: `mutation {
                        upsertRestaurantMenu(catalogue: {
                            name: "${values.name}"
                            stores: ${JSON.stringify(selectedStores).replace(/"([^"]+)":/g, '$1:')}
                        }) {
                            status
                            message
                        }
                    }`
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${access_token}`,
                    }
                }
            );

            if (response?.data?.data?.upsertRestaurantMenu?.status === "200") {
                setIsModalVisible(false);
                await getRestaurantMenus(values);
                form.resetFields();
            }
        } catch (error) {
            console.error("Error creating menu:", error);
        } finally {
            setLoading(false);
        }
    };


    const filteredMenuList = menuList.filter(menu =>
        menu.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (menu.name2 && menu.name2.includes(searchTerm))
    );

    return (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} className="spinLoader" spin />} spinning={loading}>
            <div>
                {selectedMenu ? (
                    <MenuDetails onBack={() => setSelectedMenu(null)} selectedMenu={selectedMenu} />
                ) : (
                    <>
                        <Row
                            justify="space-between"
                            align="middle"
                            gutter={16}
                            style={{
                                marginBottom: 16,
                            }}
                        >
                            <Col flex="auto">
                                <Typography.Title level={3} style={{ margin: 0 }}>Menu Management</Typography.Title>
                            </Col>

                            <Col flex="300px">
                                <Input
                                    placeholder="Search menus..."
                                    prefix={<SearchOutlined style={{ color: "#d7dade" }} />}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    style={{ borderRadius: "8px" }}
                                    value={searchTerm}
                                />
                            </Col>

                            <Col>
                                <Button
                                    type="primary"
                                    icon={<PlusOutlined />}
                                    onClick={() => setIsModalVisible(true)}
                                    style={{ borderRadius: "8px" }} 
                                >
                                    Add New
                                </Button>
                            </Col>
                        </Row>

                        <Row gutter={[16, 16]} justify="start">
                            {filteredMenuList.map((menu, index) => (
                                <Col key={index} xs={24} sm={12} md={8}>
                                    <Card
                                        style={{
                                            textAlign: "left",
                                            borderRadius: "8px",
                                            padding: "1em",
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "column"
                                        }}
                                    >
                                        <Typography.Title level={4}>{menu.name}</Typography.Title>
                                        {menu.name2 && (
                                            <Typography.Text>{menu.name2}</Typography.Text>
                                        )}
                                        <div style={{ marginTop: "auto", paddingTop: "10px" }}>
                                            <Button
                                                type="primary"
                                                style={{ backgroundColor: "black", borderRadius: "8px" }}
                                                onClick={() => setSelectedMenu(menu)}
                                            >
                                                Manage Menu
                                            </Button>
                                        </div>
                                    </Card>
                                </Col>
                            ))}
                        </Row>

                        <Modal
                            title="Create New Menu"
                            visible={isModalVisible}
                            onCancel={() => {
                                setIsModalVisible(false);
                                form.resetFields();
                            }}
                            footer={[
                                <Button
                                    key="cancel"
                                    onClick={() => {
                                        setIsModalVisible(false);
                                        form.resetFields();
                                    }}
                                    style={{ borderRadius: "8px" }}
                                >
                                    Cancel
                                </Button>,
                                <Button
                                    key="submit"
                                    type="primary"
                                    onClick={() => form.submit()}
                                    style={{ borderRadius: "8px" }}
                                >
                                    OK
                                </Button>
                            ]}
                        >
                            <Form
                                form={form}
                                layout="vertical"
                                onFinish={handleCreateMenu}
                            >
                                <Row gutter={16}>
                                    <Col  span={12}>
                                        <Form.Item
                                            name="name"
                                            label="Menu Name"
                                            rules={[{ required: true, message: 'Please input menu name!' }]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name="store"
                                            label="Store"
                                            rules={[{ required: true, message: 'Please select a store!' }]}
                                        >
                                            <Select
                                                mode="multiple"
                                                options={storeOptions}
                                                placeholder="Select store"

                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Modal>
                    </>
                )}
            </div>
        </Spin>
    );
};

export default MenuManagement;