import { useState, useEffect } from "react";
import { Card, Table, Button, Switch, Typography, Select, Modal, Form, Input, InputNumber, Spin, Row, Col, Tooltip, Tabs,message } from "antd";
import { LeftOutlined, LoadingOutlined, EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import Axios from "axios";
import { getOAuthHeaders } from "../../../constants/oAuthValidation";
import { v4 as uuidv4 } from "uuid";
import BillOfMaterialModal from "./BillOfMaterialModal";

const { Option } = Select;
const { TabPane } = Tabs;

const MenuDetails = ({ onBack, selectedMenu }) => {
  const [categoryId, setCategoryId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isAddNewModalVisible, setIsAddNewModalVisible] = useState(false);
  const [isBOMModalVisible, setIsBOMModalVisible] = useState(false);
  const [selectedBillOfMaterial, setSelectedBillOfMaterial] = useState([]);
  const [selectedBOMmProductId, setSelectedBOMmProductId] = useState(null);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [selectedStore, setSelectedStore] = useState(
    selectedMenu?.stores && selectedMenu.stores.length > 0
      ? selectedMenu.stores[0].csBunitId
      : ""
  );
  const [menuDetails, setMenuDetails] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [productList, setProductList] = useState([]);
  const [taxList, setTaxList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [activeTab, setActiveTab] = useState("GENERAL");
  const [saleTypes, setSaleTypes] = useState([]);
  const [form] = Form.useForm();
  const [newCatform] = Form.useForm();

  const serverUrl = process.env.REACT_APP_serverUrl;
  const genericUrl = process.env.REACT_APP_genericUrl;
  const { access_token } = getOAuthHeaders();

  const handleOpenModal = (billOfMaterial,mProductId) => {
    setSelectedBillOfMaterial(billOfMaterial);
    setSelectedBOMmProductId(mProductId)
    setIsBOMModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsBOMModalVisible(false);
    setSelectedBOMmProductId(null)
    setSelectedBillOfMaterial([]);
  };

  const fetchDropdownData = async () => {
    try {
      // Fetch product list
      const productResponse = await Axios.post(
        serverUrl,
        {
          query: `query {
            getRestaurantMenuProductList(
              categoryId: "${categoryId}",
              catalogueId: "${selectedMenu.cWRPCatalogueID}"
            ) {
              mProductId
              name
              value
            }
          }`,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );

      // Fetch tax list
      const taxResponse = await Axios.post(
        genericUrl,
        {
          query: `
query {
                getTableData(
                  tableName: "cs_tax",
                  columns: "cs_tax_id,name,rate",
                  whereClause: "isactive='Y'",
                  orderByClause: null,
                  limit: 100
                ) {
                  data
                }
              }`,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );

      setProductList(productResponse.data.data.getRestaurantMenuProductList);
      setTaxList(JSON.parse(taxResponse.data?.data?.getTableData?.data));
    } catch (error) {
      console.error("Error fetching dropdown data:", error);
    }
  };

  useEffect(() => {
    fetchDropdownData();
  }, [categoryId]);

  useEffect(() => {
    const getCategoryResponse = async () => {
      const CategoryResponse = await Axios.post(
        serverUrl,
        {
          query: `
query {
    getRestaurantMenuCategory(catalogueId: "${selectedMenu.cWRPCatalogueID}") {

              mProductCategoryId
              productCategoryName
    }
}`,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (CategoryResponse.status === 200) {
        setCategoryList(CategoryResponse.data.data.getRestaurantMenuCategory)
        const options = CategoryResponse.data.data.getRestaurantMenuCategory.map(store => ({
          label: store.productCategoryName,
          value: store.mProductCategoryId
        }));
        setCategoryOptions(options);
      }
    }

    getCategoryResponse()
  }, [])

  // Group menu items by sale type and category
  const getFilteredMenuItems = () => {
    // If no active tab or GENERAL tab, show items with null saleTypes
    if (activeTab === "GENERAL") {
      const itemsWithNullSaleTypes = menuDetails.filter(item =>
        !item.mProduct.saleTypes || item.mProduct.saleTypes.length === 0
      );
      return groupItemsByCategory(itemsWithNullSaleTypes);
    } else {
      // Filter items by selected sale type
      const filteredItems = menuDetails.filter(item =>
        item.mProduct.saleTypes &&
        item.mProduct.saleTypes.some(type => type.saleTypeId === activeTab)
      );
      return groupItemsByCategory(filteredItems);
    }
  };

  // Helper function to group items by category
  const groupItemsByCategory = (items) => {
    return items.reduce((acc, item) => {
      const category = item.mProduct.productCategoryName;
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(item);
      return acc;
    }, {});
  };

  // Extract unique sale types from menu items
  const extractSaleTypes = () => {
    const typesMap = new Map();

    // Add a default "GENERAL" tab for items with null saleTypes
    typesMap.set("GENERAL", { saleTypeId: "GENERAL", name: "General", value: "General" });

    menuDetails.forEach(item => {
      if (item.mProduct.saleTypes && item.mProduct.saleTypes.length > 0) {
        item.mProduct.saleTypes.forEach(type => {
          if (!typesMap.has(type.saleTypeId)) {
            typesMap.set(type.saleTypeId, type);
          }
        });
      }
    });

    return Array.from(typesMap.values());
  };

  useEffect(() => {
    if (menuDetails.length > 0) {
      const types = extractSaleTypes();
      setSaleTypes(types);
    }
  }, [menuDetails]);

  const handleEdit = (record) => {
    setEditingItem(record);
    setCategoryId(record.mProductCategoryId);

    // Determine the proper price based on active tab/sale type
    let priceToUse = record.slistprice;

    // If we're in a specific sale type tab and the record has matching sale types
    if (activeTab !== "GENERAL" &&
      record.mProduct?.saleTypes &&
      record.mProduct.saleTypes.length > 0) {

      // Find the matching sale type
      const activeSaleType = record.mProduct.saleTypes.find(type =>
        type.saleTypeId === activeTab
      );

      // If found, use its price
      if (activeSaleType) {
        priceToUse = activeSaleType.priceList || activeSaleType.priceStd || record.slistprice;
      }
    }

    form.setFieldsValue({
      name: record.mProduct.name,
      price: priceToUse,
      taxName: record.cTaxId,
      isactive: record.isactive,
    });
    setIsModalVisible(true);
  };

  const handleAdd = (categoryName, items) => {
    const selectedCategory = items.find((data) => data.mProduct.productCategoryName === categoryName);

    if (selectedCategory) {
      setCategoryId(selectedCategory.mProduct.mProductCategoryId);
    } else {
      setCategoryId(null); // Reset if no match
    }
    setEditingItem(null);
    form.resetFields();
    setIsModalVisible(true);
  };

  const handleModalOk = async (newCatagoryform) => {
    try {
      let values;
      if (newCatagoryform === "newCatform") {
        values = await newCatform.validateFields();
      } else {
        values = await form.validateFields();
      }
  
      const generatedId = editingItem?.cwrPcataloguelinesId || uuidv4().replace(/-/g, "").toUpperCase();
  
      const mutationQuery = {
        query: `
          mutation {
            upsertRestaurantMenuDetails(catalogueLine: {
              productId: "${editingItem?.mProductId || values.name}"
              cwrPcatalogueId: "${selectedMenu.cWRPCatalogueID}"
              cwrPcataloguelinesId: "${generatedId}"
              isactive: ${values.isactive || false}
              cTaxId: ${values.taxName ? `"${values.taxName}"` : null}
              sunitprice: ${values.price || 0}
              slistprice: ${values.price || 0}
              saleTypeId: ${activeTab !== "GENERAL" ? `"${activeTab}"` : null}
              csBunitId:  "${selectedStore}"
            }) {
              status
              message
            }
          }
        `,
      };
  
      const response = await Axios.post(serverUrl, mutationQuery, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });
  
      const apiResponse = response.data?.data?.upsertRestaurantMenuDetails;
  
      if (apiResponse?.status === "200") {
        message.success(apiResponse.message || "Menu details updated successfully!");
  
        if (newCatagoryform === "newCatform") {
          setIsAddNewModalVisible(false);
          newCatform.resetFields();
        } else {
          setIsModalVisible(false);
          form.resetFields();
        }
        getRestaurantMenuDetails();
      } else {
        message.error(apiResponse?.message || "Something went wrong, please try again.");
      }
    } catch (error) {
      message.error("Failed to update menu details.");
      console.error("Validation or API call failed:", error);
    }
  }
  const onSwitchChange = async (record, checked) => {
    try {
      const mutationQuery = {
        query: `
          mutation {
            upsertRestaurantMenuDetails(catalogueLine: {
              productId: "${record?.mProductId}"
              cwrPcatalogueId: "${selectedMenu.cWRPCatalogueID}"
              cwrPcataloguelinesId: "${record?.cwrPcataloguelinesId}"
              isactive: ${checked}
              cTaxId: "${record?.cTaxId}"
              sunitprice: 0
              slistprice: 0
              saleTypeId: null
              csBunitId: null
            }) {
              status
              message
            }
          }
        `,
      };
      const response = await Axios.post(serverUrl, mutationQuery, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });

      if (response.data.data.upsertRestaurantMenuDetails.status === "200") {
        message.success(response.data.data.upsertRestaurantMenuDetails.message || "Menu details updated successfully!");
        getRestaurantMenuDetails();
      }
      else {
        message.error(response.data.data.upsertRestaurantMenuDetails.message || "Something went wrong, please try again.");
      }

    } catch (error) {
      message.error("Failed to update menu details.");
      console.error("Validation or API call failed:", error);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: ["mProduct", "name"],
      key: "name",
    },
    {
      title: "Product Code",
      dataIndex: ["mProduct", "value"],
      key: "value",
    },
    {
      title: "Price",
      dataIndex: "slistprice",
      key: "price",
      render: (price, record) => {
        let displayPrice = price;

        // Check if this item has sale types and we're in a specific sale type tab
        if (activeTab !== "GENERAL" &&
          record.mProduct?.saleTypes &&
          record.mProduct.saleTypes.length > 0) {

          // Find the matching sale type for the active tab
          const activeSaleType = record.mProduct.saleTypes.find(type =>
            type.saleTypeId === activeTab
          );

          // If found, use its price (priceList or priceStd, depending on your data structure)
          if (activeSaleType) {
            displayPrice = activeSaleType.priceList || activeSaleType.priceStd || price;
          }
        }

        if (displayPrice === null || displayPrice === undefined || isNaN(displayPrice)) {
          return price;
        }

        return `₹${parseFloat(displayPrice).toFixed(2)}`;
      },
    },
    {
      title: "On Hand Qty",
      dataIndex: "onhandQty",
      key: "onhandQty",
    },
    {
      title: "Tax",
      dataIndex: "taxName",
      key: "tax",
    },
    {
      title: "Tax Rate",
      dataIndex: "taxRate",
      key: "taxRate",
      render: (rate) => `${rate}%`,
    },
    {
      title: "Status",
      dataIndex: "isactive",
      key: "status",
      render: (active, record) => (
        <Switch
          checked={active}
          onChange={(checked) => {
            onSwitchChange(record, checked);
          }}
        />
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <div style={{ display: "flex", alignItems: "center", gap: "8px", whiteSpace: "nowrap" }}>
          <Button
            type="link"
            onClick={() => handleEdit(record)}
            disabled={selectedMenu?.taxConfig === "N" && record?.mProduct?.saleTypes === null}
            style={{ color: selectedMenu?.taxConfig === "Y" || record?.mProduct?.saleTypes !== null ? "black" : "gray", cursor: selectedMenu?.taxConfig === "Y" || record?.mProduct?.saleTypes !== null ? "pointer" : "not-allowed", }}
          >
            Edit
          </Button>
          {record.mProduct.isBOMRequired === "Y" && record.mProduct?.billOfMaterial?.length > 0 && (
            <Tooltip title="View Receipe">
              <img
                src="https://static.thenounproject.com/png/2434646-200.png"
                alt="Bill of Material"
                style={{ cursor: "pointer", width: "20px", verticalAlign: "middle", marginBottom: "0.2em" }}
                onClick={() => handleOpenModal(record.mProduct.billOfMaterial,record.mProductId)}
              />
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  const getRestaurantMenuDetails = async () => {
    if (!selectedStore) {
      console.error("No selected store found");
      return;
    }
    setLoading(true)
    try {
      const response = await Axios.post(
        serverUrl,
        {
          query: `query {
            getRestaurantMenuDetails(bunitId: "${selectedStore}", limit: 1000, offset: 0) {
              cwrPcataloguelinesId
              mProductId
              isactive
              onhandQty
              slistprice
              sunitprice
              taxName
              taxRate
              cTaxId
              mProduct {
                name
                value
                mProductCategoryId
                productCategoryName
                name2
                imageurl
                csTaxcategoryId
                isBOMRequired
                productAddons {
                  mProductAddOnId
                  name
                  price
                  mAddonGroup {
                    mAddonGroupId
                    name
                    maxqty
                    minqty
                    type
                  }
                }
                billOfMaterial {
                  name
                  mProductId
                  qty
                  mProductBomId
                }
                  saleTypes {
                saleTypeId
                name
                value
                priceList
                priceStd
                isDefault
            }
              }
            }
          }`,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      setMenuDetails(response.data.data.getRestaurantMenuDetails);
    } catch (error) {
      console.error("Error fetching menu details:", error);
    }
    finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getRestaurantMenuDetails();
  }, [selectedStore, serverUrl]);

  const handleProductChange = (productId) => {
    const selectedProduct = productList.find((product) => product.mProductId === productId);

    if (selectedProduct) {
      form.setFieldsValue({ value: selectedProduct.value });
    }
  };

  const handleTabChange = (activeKey) => {
    setActiveTab(activeKey);
  };

  const groupedMenuItems = getFilteredMenuItems();
  return (
    <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} className="spinLoader" spin />} spinning={loading}>
      <div>
        {/* Header with Back Button and Store Select */}
        <Row justify="space-between" align="middle" style={{ marginBottom: "1em" }}>
          {/* Left Side - Back Button */}
          <Col>
            <Button
              onClick={onBack}
              style={{ borderRadius: "8px", backgroundColor: "black", color: "#fff" }}
            >
              <LeftOutlined style={{ color: "#fff" }} /> Back to Menu Selection
            </Button>
          </Col>

          {/* Right Side - Store Select & Add New Button */}
          <Col>
            <Row gutter={16} align="middle">
              {selectedMenu?.stores && selectedMenu.stores.length > 0 && (
                <Col>
                  <Typography.Text strong>Store: </Typography.Text>
                  <Select
                    value={selectedStore}
                    onChange={(value) => setSelectedStore(value)}
                    style={{ width: 250 }}
                  >
                    {selectedMenu.stores.map((store) => (
                      <Select.Option key={store.csBunitId} value={store.csBunitId}>
                        {store.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
              )}
              <Col>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  style={{ borderRadius: "8px" }}
                  onClick={() => setIsAddNewModalVisible(true)}
                >
                  Add New
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* Menu Title */}
        <Typography.Title level={2} style={{ textAlign: "left" }}>
          {selectedMenu?.name}
        </Typography.Title>

        {/* Tabs for different sale types */}
        <Tabs activeKey={activeTab} onChange={handleTabChange} tabPosition="top" style={{ marginBottom: 20 }}>
          {saleTypes.map(type => (
            <TabPane tab={type.value} key={type.saleTypeId}>
              {/* Render Categories for current tab */}
              {Object.entries(groupedMenuItems).length > 0 ? (
                Object.entries(groupedMenuItems).map(([categoryName, items]) => (
                  <Card
                    key={categoryName}
                    style={{ marginBottom: "20px", borderRadius: "8px", padding: "1em" }}
                  >
                    <Typography.Title level={4}>{categoryName}</Typography.Title>

                    <Table
                      dataSource={items}
                      columns={columns}
                      rowKey="cwrPcataloguelinesId"
                      pagination={false}
                    />

                    <Button
                      type="primary"
                      style={{ marginTop: "10px", borderRadius: "8px" }}
                      onClick={() => handleAdd(categoryName, items)}
                    >
                      + Add Item
                    </Button>
                  </Card>
                ))
              ) : (
                <Typography.Text>No items found for this sale type.</Typography.Text>
              )}
            </TabPane>
          ))}
        </Tabs>

        {/* Edit/Add Modal */}
        <Modal
          title={editingItem ? "Edit Item" : "Add New Item"}
          visible={isModalVisible}
          onOk={handleModalOk}
          onCancel={() => setIsModalVisible(false)}
        >
          <Form form={form} layout="vertical">
            <Row gutter={[16, 16]}>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[{ required: true, message: "Please select a product!" }]}
                >
                  <Select
                    disabled={!!editingItem}
                    placeholder="Select a product"
                    showSearch
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    onChange={handleProductChange}
                  >
                    {productList.map((product) => (
                      <Select.Option key={product.mProductId} value={product.mProductId}>
                        {product.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              {
                (editingItem?.mProduct?.saleTypes !== null || !editingItem) && (
                  <Col className="gutter-row" span={12}>
                    <Form.Item
                      name="price"
                      label="Price"
                      rules={[{ required: true, message: "Please input price!" }]}
                    >
                      <InputNumber prefix="₹" style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                )
              }

              {
                (selectedMenu?.taxConfig === "Y" || !editingItem) && (
                  <Col className="gutter-row" span={12}>
                    <Form.Item
                      name="taxName"
                      label="Tax Name"
                    >
                      <Select
                        placeholder="Select a tax"
                        showSearch
                        optionFilterProp="children"
                        style={{ width: "100%" }}
                      >
                        {taxList.map((tax) => (
                          <Select.Option key={tax.cs_tax_id} value={tax.cs_tax_id}>
                            {tax.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )
              }

              <Col className="gutter-row" span={12}>
                <Form.Item name="isactive" label="Status" valuePropName="checked">
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
        <BillOfMaterialModal
          isVisible={isBOMModalVisible}
          onClose={handleCloseModal}
          selectedBillOfMaterial={selectedBillOfMaterial}
          selectedBOMmProductId={selectedBOMmProductId}
        />

        <Modal
          title="Create New"
          visible={isAddNewModalVisible}
          onCancel={() => {
            setIsAddNewModalVisible(false);
            newCatform.resetFields();
          }}
          footer={[
            <Button
              key="cancel"
              onClick={() => {
                setIsAddNewModalVisible(false);
                newCatform.resetFields();
              }}
              style={{ borderRadius: "8px" }}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() => handleModalOk("newCatform")}
              style={{ borderRadius: "8px" }}
            >
              OK
            </Button>
          ]}
        >
          <Form
            form={newCatform}
            layout="vertical"
          >
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  name="category"
                  label="Category"
                  rules={[{ required: true, message: 'Please select a category!' }]}
                >
                  <Select
                    showSearch
                    options={categoryOptions}
                    placeholder="Select store"
                    onChange={(value) => { setCategoryId(value) }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[{ required: true, message: "Please select a product!" }]}
                >
                  <Select
                    disabled={!!editingItem}
                    placeholder="Select a product"
                    showSearch
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    onChange={handleProductChange}
                  >
                    {productList.map((product) => (
                      <Select.Option key={product.mProductId} value={product.mProductId}>
                        {product.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  name="price"
                  label="Price"
                  rules={[{ required: true, message: "Please input price!" }]}
                >
                  <InputNumber prefix="₹" style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  name="taxName"
                  label="Tax Name"
                >
                  <Select
                    placeholder="Select a tax"
                    showSearch
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                  >
                    {taxList.map((tax) => (
                      <Select.Option key={tax.cs_tax_id} value={tax.cs_tax_id}>
                        {tax.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                <Form.Item name="isactive" label="Status" valuePropName="checked">
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    </Spin>
  );
};

export default MenuDetails;