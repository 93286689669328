import React, { useState, useEffect } from "react";
import { Table } from "antd";
import { Resizable } from "react-resizable";

const ResizableCell = (props) => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

const components = {
  header: {
    cell: ResizableCell,
  },
};

const TableForImport = (props) => {
  const { columnsData, gridData, rowSelection, switcher, downloadTemplateFlag, setDownloadTemplateFlag,showSwitch, setShowSwitch,setShowDownloadTemplateOption,setVerifyAndImportFlag,setTableDataCopy,setTableData,setTableHeader,setSortedTableHeader } = props;
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (!downloadTemplateFlag && showSwitch && !switcher) {
      const nameColumns = gridData.map((item) => ({
        title: item.Name, 
        dataIndex: item.Name,
        key: item.Name,
        width: 150,
      }));
      setColumns(nameColumns);
      setData(null)
    } else if(showSwitch){
      setColumns(columnsData || []);
      setData(gridData)
    }
  }, [columnsData, switcher]);
  

  const handlePaste = (event) => {
    event.preventDefault();
    // setShowSwitch(false)
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("text");
    const rows = pastedData.split("\n").filter((row) => row.trim());
    const sortedFieldArray = [];
    if (rows.length > 0) {
      const headers = rows[0].split("\t");
      const dynamicColumns = headers.map((header, index) => ({
        title: header || `Column ${index + 1}`,
        dataIndex: header.trim(),
        key: `col${index}`,
        width: 150,
      }));
      for (let index = 0; index < dynamicColumns.length; index += 1) {
        sortedFieldArray.push(dynamicColumns[index].dataIndex);
      }
      setSortedTableHeader(sortedFieldArray)
      setTableHeader(dynamicColumns)
      setColumns(dynamicColumns);
      setDownloadTemplateFlag(true);

      const newData = rows.slice(1).map((row, rowIndex) => {
        const values = row.split("\t");
        const rowData = { };
        dynamicColumns.forEach((col, colIndex) => {
          rowData[col.dataIndex] = values[colIndex] || "";
        });
        return rowData;
      });

      setData(newData);
      setShowDownloadTemplateOption(true)
      setDownloadTemplateFlag(true)
      setVerifyAndImportFlag(true)
      setTableData(newData)
      setTableDataCopy(newData)
    }
  };

  return (
    <div
      onPaste={gridData.length > 0 ? handlePaste : null}
      tabIndex="0"
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      style={{
        padding: "10px",
        border: `1px dashed ${isFocused ? "#1890ff" : "#ccc"}`,
        borderRadius: "4px",
        marginBottom: "20px",
        outline: "none",
        backgroundColor: isFocused ? "#e6f7ff" : "white",
        transition: "border-color 0.3s, background-color 0.3s",
      }}
    >
      <div style={{ marginBottom: "10px", display: gridData.length > 0 ? "block" : "none" }}>
        Click here and paste (Ctrl+V) your data to populate the table.
      </div>
      <Table
        size="small"
        sticky={true}
        scroll={{ y: "58vh", x: "100%" }}
        columns={columns}
        components={components}
        dataSource={data}
        pagination={false}
        rowSelection={rowSelection}
      />
    </div>
  );
};


export default TableForImport;
