import React, { useState, useEffect } from 'react';
import { Modal, Table, Button, Typography, Input, InputNumber, Popconfirm, Form, Select, message } from 'antd';
import { DeleteOutlined, SearchOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import axios from 'axios';
import { getOAuthHeaders } from "../../../constants/oAuthValidation";
import { v4 as uuidv4 } from "uuid";



const BillOfMaterialModal = ({ isVisible, onClose, selectedBillOfMaterial,selectedBOMmProductId }) => {
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [hasChanges, setHasChanges] = useState(false);
  const [productOptions, setProductOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [productSearchText, setProductSearchText] = useState('');
  const serverUrl = process.env.REACT_APP_serverUrl;
    const genericUrl = process.env.REACT_APP_genericUrl;
    const { access_token } = getOAuthHeaders();
  
  useEffect(() => {
    // Reset data when modal opens with new data
    if (isVisible && selectedBillOfMaterial) {
      // Deep clone the data to keep original reference
      const clonedData = JSON.parse(JSON.stringify(selectedBillOfMaterial));
      setDataSource(clonedData);
      setOriginalData(clonedData);
      setSearchText('');
      setHasChanges(false);
      fetchProductOptions();
    }
  }, [isVisible, selectedBillOfMaterial]);

  const fetchProductOptions = async (searchValue = '') => {
    try {
      setLoading(true);
      const whereClause = searchValue 
        ? `name ilike '%${searchValue}%' or value ilike '%${searchValue}%'` 
        : "name ilike '%%' or value ilike '%%'";
      
      const query = {
        query: `{
          getTableData(
            tableName: "m_product",
            columns: "m_product_id,name,value",
            whereClause: "${whereClause}",
            orderByClause: null,
            limit: 100
          ) {
            data
          }
        }`
      };

      const response = await axios.post(genericUrl, query, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });
      
      const productData = JSON.parse(response.data?.data?.getTableData?.data || '[]');
      setProductOptions(productData);
    } catch (error) {
      console.error("Error fetching product options:", error);
      message.error("Failed to load product options");
    } finally {
      setLoading(false);
    }
  };

  const handleProductSearch = (value) => {
    setProductSearchText(value);
    if (value.length >= 2) {
      fetchProductOptions(value);
    }
  };

  const handleFieldChange = (value, key, dataIndex) => {
    const newData = [...dataSource];
    const index = newData.findIndex(item => (item.key || item.mProductId) === key);
    
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, { ...item, [dataIndex]: value });
      setDataSource(newData);
      
      // Check if there are any changes compared to original data
      const hasAnyChanges = checkForChanges(newData);
      setHasChanges(hasAnyChanges);
    }
  };

  const handleProductSelect = (value, key) => {
    const newData = [...dataSource];
    const index = newData.findIndex(item => (item.key || item.mProductId) === key);
    
    if (index > -1) {
      const selectedProduct = productOptions.find(p => p.m_product_id === value);
      if (selectedProduct) {
        newData.splice(index, 1, { 
          ...newData[index], 
          mProductId: selectedProduct.m_product_id,
          name: selectedProduct.name,
          value: selectedProduct.value
        });
        setDataSource(newData);
        
        // Check if there are any changes compared to original data
        const hasAnyChanges = checkForChanges(newData);
        setHasChanges(hasAnyChanges);
      }
    }
  };

  const checkForChanges = (currentData) => {
    // Compare with original data to see if anything has changed
    if (currentData.length !== originalData.length) return true;
    
    // Check for modified items or new items
    const hasModifiedItems = currentData.some((item) => {
      if (item.isNew) return true;
      
      const originalItem = originalData.find(orig => 
        (orig.key || orig.mProductId) === (item.key || item.mProductId)
      );
      
      if (!originalItem) return true; // Item doesn't exist in original data
      
      // Check if any field is different
      return item.name !== originalItem.name || item.qty !== originalItem.qty;
    });
    
    return hasModifiedItems;
  };

  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.mProductId !== key);
    setDataSource(newData);
    setHasChanges(true);
  };

  const handleAdd = () => {
    const newItem = {
      key: `new-${Date.now()}`,
      mProductId: '',
      name: '',
      qty: 1,
      isNew: true
    };
    setDataSource([...dataSource, newItem]);
    setHasChanges(true);
    
    // Make sure we have product options
    if (productOptions.length === 0) {
      fetchProductOptions();
    }
  };

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const handleSaveAll = async () => {
    // Get only the modified data for saving
    const modifiedData = getModifiedData();
    // console.log('Saving only modified BOM data:', modifiedData);
    
    try {
      setLoading(true);
      
      // Format products for the mutation
      const productsToUpsert = [
        ...modifiedData.newItems.map(item => ({
          mProductBomId: item.mProductBomId || uuidv4().replace(/-/g, "").toUpperCase(), // Main product ID
          qty: item.qty,
          mBomProductId:item.mProductId,
          mProductId: selectedBOMmProductId
        })),
        ...modifiedData.modifiedItems.map(item => ({
          mProductBomId: item.mProductBomId || uuidv4().replace(/-/g, "").toUpperCase(), // Main product ID
          qty: item.qty,
          mBomProductId: item.mProductId,
          mProductId: selectedBOMmProductId
        }))
      ];
      
      if (productsToUpsert.length > 0) {
        const mutation = {
          query: `mutation {
            upsertBOMProducts(
              products: ${JSON.stringify(productsToUpsert).replace(/"([^"]+)":/g, '$1:')}
            ) {
              status
              message
            }
          }`
        };
        
        const response = await axios.post(serverUrl, mutation, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        });
        
        const result = response.data?.data?.upsertBOMProducts;
        
        if (result && result.status === "200") {
          message.success("BOM updated successfully");
          
          // Handle deletions separately if needed
          // ...Add delete mutation here if you have that API...
          
          // Update originalData to reflect current state
          setOriginalData([...dataSource]);
          setHasChanges(false);
        } else {
          message.error(result?.message || "Failed to update BOM");
        }
      } else {
        message.info("No changes to save");
      }
    } catch (error) {
      console.error("Error saving BOM data:", error);
      message.error("Failed to save changes");
    } finally {
      setLoading(false);
    }
  };

  const getModifiedData = () => {
    // Identify and return only records that have been changed
    const newItems = dataSource.filter(item => item.isNew);
    
    const modifiedItems = dataSource.filter(item => {
      if (item.isNew) return false; // Skip new items (already handled)
      
      const originalItem = originalData.find(orig => 
        (orig.key || orig.mProductId) === (item.key || item.mProductId)
      );
      
      if (!originalItem) return false; // Should never happen, but skip if does
      
      // Return true only if any field has changed
      return item.name !== originalItem.name || item.qty !== originalItem.qty;
    });
    
    // Get IDs of deleted items
    const originalIds = originalData.map(item => item.key || item.mProductId);
    const currentIds = dataSource.map(item => item.key || item.mProductId);
    const deletedIds = originalIds.filter(id => !currentIds.includes(id));
    
    return {
      newItems,
      modifiedItems,
      deletedIds
    };
  };

  const filteredData = searchText
    ? dataSource.filter(item => 
        item.name.toLowerCase().includes(searchText.toLowerCase()))
    : dataSource;

  // Custom styles
  const inputStyle = {
    borderRadius: '6px'
  };

  const columns = [
    { 
      title: "Name", 
      dataIndex: "name", 
      key: "name",
      render: (text, record) => {
        if (record.isNew) {
          return (
            <Select
              showSearch
              value={record.mProductId || undefined}
              placeholder="Select a product"
              optionFilterProp="children"
              onChange={(value) => handleProductSelect(value, record.key || record.mProductId)}
              onSearch={handleProductSearch}
              loading={loading}
              filterOption={false}
              style={{ width: '100%', ...inputStyle }}
            >
              {productOptions.map(product => (
                <Select.Option key={product.m_product_id} value={product.m_product_id}>
                  {product.name} ({product.value})
                </Select.Option>
              ))}
            </Select>
          );
        }
        return <div>{text}</div>;
      },
      width: '50%'
    },
    { 
      title: "Quantity", 
      dataIndex: "qty", 
      key: "qty",
      sorter: (a, b) => a.qty - b.qty,
      render: (value, record) => (
        <InputNumber 
          min={0} 
          value={value}
          onChange={(newValue) => handleFieldChange(newValue, record.key || record.mProductId, 'qty')}
          style={{ ...inputStyle, width: '100%' }}
        />
      ),
      width: '35%'
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Popconfirm 
          title="Are you sure you want to delete this item?" 
          onConfirm={() => handleDelete(record.mProductId)}
        >
          <Button type="link" style={{ color: "black", padding: '0px 8px' }}>
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      ),
      width: '15%',
      align: 'center'
    }
  ];

  // Button styles
  const buttonStyle = {
    borderRadius: '6px'
  };

  return (
    <Modal
      title="Bill of Material"
      visible={isVisible}
      onCancel={() => {
        if (hasChanges) {
          if (window.confirm("You have unsaved changes. Are you sure you want to close?")) {
            onClose();
          }
        } else {
          onClose();
        }
      }}
      footer={[
        <Button 
          key="add" 
          type="primary" 
          icon={<PlusOutlined />} 
          onClick={handleAdd}
          style={buttonStyle}
          loading={loading}
        >
          Add Item
        </Button>,
        hasChanges && (
          <Button 
            key="save" 
            type="primary" 
            icon={<SaveOutlined />} 
            onClick={handleSaveAll}
            loading={loading}
            style={{ 
              backgroundColor: "#52c41a", 
              borderColor: "#52c41a",
              ...buttonStyle
            }}
          >
            Save All Changes
          </Button>
        ),
        <Button key="cancel" onClick={onClose} style={buttonStyle} disabled={loading}>
          Close
        </Button>
      ]}
      width={700}
      bodyStyle={{ maxHeight: 600, overflowY: "auto" }}
    >
      <div style={{ marginBottom: 16 }}>
        <Input 
          placeholder="Search items..." 
          prefix={<SearchOutlined />}
          value={searchText}
          onChange={(e) => handleSearch(e.target.value)}
          style={{ width: 250, ...inputStyle }}
        />
      </div>
      
      <Form form={form} component={false}>
        <Table
          dataSource={filteredData}
          columns={columns}
          rowKey={record => record.mProductId || record.key}
          pagination={false}
          scroll={{ y: 300 }}
          loading={loading}
          onRow={() => ({
            style: { cursor: "default" },
          })}
        />
      </Form>
      
      {filteredData.length === 0 && !searchText && (
        <Typography.Text>No Bill of Material Available</Typography.Text>
      )}
      
      {filteredData.length === 0 && searchText && (
        <Typography.Text>No items match your search criteria</Typography.Text>
      )}
    </Modal>
  );
};

export default BillOfMaterialModal;